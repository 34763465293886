import { Box, Flex, Spacer, Text, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Title from "../components/Header/Title";

function About() {
  const variant = useBreakpointValue({ base: "mobile", sm: "mobile", md: "mobile", lg: "desktop", xl: "desktop" });
  const { colorMode } = useColorMode();

  return (
    <Box bgColor={colorMode === "light" ? "#E9E9E9" : "#1b202b"} display="flex" flexDir={"column"} minH="100vh">
      <Header />
      <Flex alignItems={"center"} minW="100%" flexDir={"column"}>
        <Title title="About" size={variant === "mobile" ? "3xl" : "5xl"} padding={variant === "mobile" ? "1.5rem" : "2.5rem"} />
        <Box
          w="100%"
          h="38vh"
          backgroundSize={"cover"}
          backgroundImage={
            "url('https://res.cloudinary.com/dbjjalcbm/image/upload/v1676942624/IMG_0085_002_6b3ed0385f.jpg?updated_at=2023-02-21T01:23:44.774Z')"
          }
          boxShadow={"rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"}
        />
        <Flex w="100%">
          <Flex
            pt={{ base: "2rem", sm: "2rem", md: "3rem", lg: "3rem", xl: "3rem" }}
            pb={{ base: "2rem", sm: "2rem", md: "4rem", lg: "4rem", xl: "4rem" }}
            pl={{ base: "2rem", sm: "2rem", md: "2rem", lg: "2rem", xl: "5rem" }}
            pr={{ base: "2rem", sm: "2rem", md: "2rem", lg: "2rem", xl: "5rem" }}
            w={{ base: "100%", sm: "100%", md: "100%", lg: "100%", xl: "72%" }}
          >
            <Text fontSize={{ base: "xl", sm: "xl", md: "xl", lg: "2xl", xl: "2xl" }}>
              I have a background in Graphic Design and developed an interest in painting when I stayed at home to look after our sons
              through their schooling years (they are now in their 20s). Over the past 25 years I have exhibited my works at several
              Wellington Galleries, and I have also completed a large number of commissions. These commissions are generally for the owners
              to celebrate or remember a place or occasion and are often painted from photos.
              <br />
              <br /> My medium is acrylic and I enjoy painting landscapes/seascapes and city scenes. At home I have my own studio and I like
              to spend time painting as an outlet outside of my day job. <br />
              <br /> My wife and I over time are collecting artworks and when we travel we like to visit galleries and purchase art as a
              reminder of our holidays.
              <br />
              <br /> Recently I have been looking at my painting style and am attempting to develop a more impressionist style.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Spacer />
      <Footer />
    </Box>
  );
}

export default About;
