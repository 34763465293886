import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Center, Heading, SimpleGrid, Text, useBreakpointValue, useColorMode } from "@chakra-ui/react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

function Main() {
  const variant = useBreakpointValue({ base: "mobile", sm: "mobile", md: "mobile", lg: "desktop", xl: "desktop" });
  const { colorMode } = useColorMode();

  return (
    <Box bgColor={colorMode === "light" ? "#E9E9E9" : "#1b202b"} minW="100%">
      <Header />
      <Center minW="100%" flexDir={"column"}>
        <Center
          height="80vh"
          width="100%"
          backgroundImage={`url('https://res.cloudinary.com/dbjjalcbm/image/upload/v1676234486/tory_channel_2_99bf96b7ab.jpg?updated_at=2023-02-12T20:41:27.052Z')`}
          backgroundSize={"cover"}
          boxShadow={"rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"}
        >
          <Heading fontSize={{ base: "2xl", sm: "3xl", md: "5xl", lg: "6xl", xl: "7xl" }} color={"white"} fontWeight={"bold"}>
            PAUL COLLINS - ARTIST
          </Heading>
        </Center>
        <ChevronDownIcon m="1rem" boxSize={12} color={colorMode === "light" ? "#dbdbdb" : "#474747"} />
        <SimpleGrid columns={variant === "mobile" ? 2 : 4} spacing={variant === "mobile" ? 10 : 16} pt="4rem" pb="4rem">
          <Box
            w={variant === "mobile" ? "32vw" : "16vw"}
            h={variant === "mobile" ? "32vw" : "16vw"}
            backgroundSize={"cover"}
            backgroundImage={
              "url('https://res.cloudinary.com/dbjjalcbm/image/upload/v1676234237/boys_2_3_0470a00266.jpg?updated_at=2023-02-12T20:37:18.004Z')"
            }
            // boxShadow={"rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"}
          />
          <Box
            w={variant === "mobile" ? "32vw" : "16vw"}
            h={variant === "mobile" ? "32vw" : "16vw"}
            backgroundSize={"cover"}
            backgroundImage={
              "url('https://res.cloudinary.com/dbjjalcbm/image/upload/v1676234238/beach_9b38595181.jpg?updated_at=2023-02-12T20:37:19.311Z')"
            }
            // boxShadow={"rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"}
          />
          <Box
            w={variant === "mobile" ? "32vw" : "16vw"}
            h={variant === "mobile" ? "32vw" : "16vw"}
            backgroundSize={"cover"}
            backgroundImage={
              "url('https://res.cloudinary.com/dbjjalcbm/image/upload/v1676234485/ferry_2_e79031ef37.jpg?updated_at=2023-02-12T20:41:26.501Z')"
            }
            // boxShadow={"rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"}
          />
          <Box
            w={variant === "mobile" ? "32vw" : "16vw"}
            h={variant === "mobile" ? "32vw" : "16vw"}
            backgroundSize={"cover"}
            backgroundImage={
              "url('https://res.cloudinary.com/dbjjalcbm/image/upload/v1676234604/cricket_6_2_bb277447ca.jpg?updated_at=2023-02-12T20:43:24.673Z')"
            }
            // boxShadow={"rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"}
          />
        </SimpleGrid>
      </Center>
      <Footer />
    </Box>
  );
}

export default Main;
