import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Header from "./components/Header/Header";
import About from "./pages/About";
import Artwork from "./pages/Artwork";
import Contact from "./pages/Contact";
import "@fontsource/josefin-sans";
import theme from "./Theme";
import "./App.css";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/Artwork" element={<Artwork />} />
        <Route path="/Contact" element={<Contact />} />
      </Routes>
    </Router>
  </ChakraProvider>
);
