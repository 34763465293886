import { Flex, Heading, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

type INavLinkProps = {
  navLink: string;
  onClick?: () => void;
};

function NavLink({ navLink, onClick }: INavLinkProps) {
  const location = useLocation();
  const isUnderlined =
    navLink.toLocaleLowerCase() === location.pathname.replace("/", "").toLocaleLowerCase() ||
    (navLink.toUpperCase() === "HOME" && location.pathname === "/");

  return (
    <Flex alignItems={"center"}>
      <Heading
        // color={{ base: "pink", sm: "red", md: "orange", lg: "yellow", xl: "green" }}
        fontSize={{ base: "2xl", sm: "3xl", md: "large", lg: "larger", xl: "2xl" }}
        fontWeight="medium"
        textDecoration={isUnderlined ? "underline" : ""}
        pl={{ base: "3rem", sm: "5rem", md: "1.4rem", lg: "2rem", xl: "3rem" }}
        pb={{ base: "0rem", sm: "3rem", md: "0rem", lg: "0rem", xl: "0rem" }}
      >
        <Link to={"/" + (navLink.toLocaleUpperCase() === "HOME" ? "" : navLink)} onClick={onClick}>
          <Text>{navLink.toLocaleUpperCase()}</Text>
        </Link>
      </Heading>
    </Flex>
  );
}

export default NavLink;
