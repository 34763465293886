import { Box, Center, Flex, Image, Spacer, Spinner, Text, useColorMode, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { useArtsQuery } from "../types";

function Artwork() {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [index, setIndex] = useState(-1);

  const { data, loading, error } = useArtsQuery();
  const newImages =
    data?.arts?.data.map((art) => ({
      src: art.attributes?.Image.data?.attributes?.url || "https://placehold.jp/150x150.png",
      original: art.attributes?.Image.data?.attributes?.url || "https://placehold.jp/150x150.png",
      width: art.attributes?.Image.data?.attributes?.width || 150,
      height: art.attributes?.Image.data?.attributes?.height || 150,
    })) || [];
  const newSlides = data?.arts?.data.map((art) => ({
    src: art.attributes?.Image.data?.attributes?.url || "https://placehold.jp/150x150.png",
    width: art.attributes?.Image.data?.attributes?.width || 150,
    height: art.attributes?.Image.data?.attributes?.height || 150,
  }));

  error &&
    toast({
      title: "Failed to fetch.",
      description: "There was a problem trying to fetch content.",
      status: "error",
      duration: 9000,
      isClosable: true,
    });

  const handleClick = (index: number, item: any) => setIndex(index);

  return (
    <Flex bgColor={colorMode === "light" ? "#E9E9E9" : "#1b202b"} flexDir="column" minW="100%" minH="100vh">
      <Header />
      <Center>
        <Box w={{ base: "100%", sm: "98%", md: "90%", lg: "85%", xl: "70%" }}>
          {error ? (
            <Center minH="100vh" minW="100%">
              <Text>Error! 🤕</Text>
            </Center>
          ) : loading ? (
            <Center minH="100vh" minW="100%">
              <Spinner />
            </Center>
          ) : (
            <Gallery rowHeight={360} margin={12} enableImageSelection={false} images={newImages} onClick={handleClick} />
          )}

          <Lightbox slides={newSlides} open={index >= 0} index={index} close={() => setIndex(-1)} />
        </Box>
      </Center>
      <Spacer />
      <Footer />
    </Flex>
  );
}

export default Artwork;
