import { Box, Center, Text, useColorMode } from "@chakra-ui/react";

type IFooterProps = {
  exampleProp?: boolean;
};

function Footer({ exampleProp }: IFooterProps) {
  const { colorMode } = useColorMode();

  return (
    <Box bg="footer" w="100%">
      <Center>
        <Text
          fontSize={{ base: "2xs", sm: "xs", md: "md", lg: "lg", xl: "lg" }}
          p="1rem"
          color={colorMode === "light" ? "#b0b0b0" : "#5D5D5D"}
        >
          Website created by Dominic McCann. All rights reserved.
        </Text>
      </Center>
    </Box>
  );
}

export default Footer;
