import { HamburgerIcon, MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  Spacer,
  useBreakpointValue,
  useDisclosure,
  Box,
  useColorMode,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import NavLink from "./NavLink";
import Title from "./Title";

type IHeaderProps = {
  exampleProp?: boolean;
};

function Header({ exampleProp }: IHeaderProps) {
  const variant = useBreakpointValue({ base: "mobile", sm: "mobile", md: "desktop", lg: "desktop", xl: "desktop" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Flex w="100%" bgColor={colorMode === "light" ? "#E9E9E9" : "#1b202b"}>
      <Link to={"/"}>
        <Title title="Paul Collins" />
      </Link>
      {variant === "mobile" ? (
        <Flex w="100%">
          <Spacer />
          <Center>
            <HamburgerIcon mr="1rem" boxSize={10} onClick={onOpen} />
            <Drawer size="full" isOpen={isOpen} placement="right" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerBody p="0" m="0">
                  <Box>
                    <Flex flexDir={"row"}>
                      <Title title="Paul Collins" />
                      <Spacer />
                      <Center>
                        {colorMode === "light" ? (
                          <SunIcon
                            mr={{ base: "1rem", sm: "2rem", md: "1rem", lg: "1rem", xl: "1rem" }}
                            boxSize={7}
                            onClick={toggleColorMode}
                          />
                        ) : (
                          <MoonIcon
                            mr={{ base: "1rem", sm: "2rem", md: "1rem", lg: "1rem", xl: "1rem" }}
                            boxSize={7}
                            onClick={toggleColorMode}
                          />
                        )}
                      </Center>
                    </Flex>

                    <Box pt="6rem">
                      <NavLink navLink="Home" onClick={onClose} />
                      <NavLink navLink="About" onClick={onClose} />
                      <NavLink navLink="Contact" onClick={onClose} />
                      <NavLink navLink="Artwork" onClick={onClose} />
                    </Box>
                  </Box>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </Center>
        </Flex>
      ) : (
        <>
          <NavLink navLink="Home" />
          <NavLink navLink="About" />
          <NavLink navLink="Contact" />
          <NavLink navLink="Artwork" />

          <Spacer />
          <Center>
            {colorMode === "light" ? (
              <SunIcon mr="1rem" boxSize={7} onClick={toggleColorMode} />
            ) : (
              <MoonIcon mr="1rem" boxSize={7} onClick={toggleColorMode} />
            )}
          </Center>
        </>
      )}
    </Flex>
  );
}

export default Header;
