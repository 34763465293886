import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Spacer,
  Text,
  Textarea,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import Footer from "../components/Footer/Footer";
import Title from "../components/Header/Title";
import emailjs from "@emailjs/browser";
import Header from "../components/Header/Header";

function Contact() {
  const variant = useBreakpointValue({ base: "mobile", sm: "mobile", md: "mobile", lg: "desktop", xl: "desktop" });
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const handleSubmit = (content: any) => {
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_SERVICE_KEY,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        {
          email: content.email,
          message: content.message,
          firstname: content.firstname,
          lastname: content.lastname,
        },
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          toast({
            title: "Email Sent.",
            description: "I will be in contact as soon as possible.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        },
        (error) => {
          toast({
            title: "Failed to send email.",
            description: "There was a problem trying to send your email: " + error.text,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      );
  };

  return (
    <Box bgColor={colorMode === "light" ? "#E9E9E9" : "#1b202b"} minW="100%" minH="100vh">
      <Header />
      <Box minW="100%" minH="90vh" display="flex" flexDir={"row"}>
        <Center
          bgColor={colorMode === "light" ? "#E9E9E9" : "#1b202b"}
          pl={{ base: "2rem", sm: "2rem", md: "5rem", lg: "6rem", xl: "6rem" }}
          pr={{ base: "2rem", sm: "2rem", md: "5rem", lg: "6rem", xl: "6rem" }}
          minH="90vh"
          maxW={variant === "desktop" ? "42rem" : "100%"}
          minW={variant === "desktop" ? "42rem" : "100%"}
          flexDir={"column"}
          // boxShadow={"10px 0 5px -2px #000"}
          // boxShadow={"5px 10px"}
          // clipPath={"inset(0px -15px 0px 0px)"}
        >
          <Center>
            <Title title="Contact" marginLeft="0" />
          </Center>
          <Text fontSize={{ base: "large", sm: "large", md: "xl", lg: "xl", xl: "2xl" }} pb="3rem">
            If you would like to book a commission piece of art to your specific requirements please contact Paul using the points of
            contact below.
          </Text>
          <Center w="100%" pb="1.5rem">
            <PhoneIcon boxSize={{ base: 8, sm: 8, md: 10, lg: 12, xl: 12 }} mr="2rem" />
            <Text fontSize={{ base: "large", sm: "large", md: "xl", lg: "xl", xl: "2xl" }}>021 124 6544</Text>
            <Spacer />
          </Center>
          <Center w="100%" pb="3rem">
            <EmailIcon boxSize={{ base: 8, sm: 8, md: 10, lg: 12, xl: 12 }} mr="2rem" />
            <Text fontSize={{ base: "large", sm: "large", md: "xl", lg: "xl", xl: "2xl" }}>lyall.collins@xtra.co.nz</Text>
            <Spacer />
          </Center>

          <Text fontSize={{ base: "large", sm: "large", md: "xl", lg: "xl", xl: "2xl" }} pb="1rem">
            Or please complete this enquiry form
          </Text>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Flex pb="1rem">
              <Input
                borderWidth={"0"}
                borderRadius="0"
                bg={colorMode === "light" ? "white" : "#30384a"}
                boxShadow={"inner"}
                mr="1rem"
                placeholder="John"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
              <Input
                borderWidth={"0"}
                borderRadius="0"
                bg={colorMode === "light" ? "white" : "#30384a"}
                boxShadow={"inner"}
                ml="1rem"
                placeholder="Doe"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Flex>

            <FormLabel>Email</FormLabel>
            <Box pb="1rem">
              <Input
                borderWidth={"0"}
                borderRadius="0"
                bg={colorMode === "light" ? "white" : "#30384a"}
                boxShadow={"inner"}
                type="email"
                placeholder="john.doe@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>
            <FormLabel>Message</FormLabel>
            <Box>
              <Textarea
                borderWidth={"0"}
                borderRadius="0"
                bg={colorMode === "light" ? "white" : "#30384a"}
                boxShadow={"inner"}
                placeholder="Write your message here."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Box>
            <Center w="100%">
              <Button
                bg="#78876D"
                borderRadius={"0"}
                m="1rem"
                onClick={() => handleSubmit({ firstname: firstname, lastname: lastname, email: email, message: message })}
              >
                <Text color="white"> Submit</Text>
              </Button>
            </Center>
          </FormControl>
        </Center>
        <Center
          backgroundRepeat={"round"}
          backgroundImage={`url('https://res.cloudinary.com/dbjjalcbm/image/upload/v1676234486/Cornwall_ab71b2063f.jpg?updated_at=2023-02-12T20:41:27.474Z')`}
          backgroundSize={"cover"}
          boxShadow={"rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"}
          display={"flex"}
          flexGrow={1}
        />
      </Box>
      <Spacer />
      <Footer />
    </Box>
  );
}

export default Contact;
