import { Box, Heading } from "@chakra-ui/react";

type ITitleProps = {
  title: string;
  size?: string;
  padding?: string;
  marginLeft?: string;
};

function Title({ title, size, padding, marginLeft }: ITitleProps) {
  return (
    <Box>
      <Heading
        whiteSpace={"nowrap"}
        fontSize={size ? size : { base: "2xl", sm: "large", md: "xl", lg: "2xl", xl: "3xl" }}
        p={padding ? padding : size ? "4rem" : "2rem"}
        ml={marginLeft ? marginLeft : size ? "" : { base: "1rem", sm: "1rem", md: "2rem", lg: "4rem", xl: "4rem" }}
      >
        {title.toLocaleUpperCase()}
      </Heading>
    </Box>
  );
}

export default Title;
